<template>
  <div class="animated fadeIn">
    <CRow>
      <el-form ref="queryForm" :model="queryForm" label-width="100px" :inline="true">
        <el-form-item label="關鍵字">
          <el-input v-model="queryForm.qstr" placeholder="序號、名稱、備註"></el-input>
        </el-form-item>
        <el-form-item label="所屬單位">
            <el-select v-model="queryForm.organization" filterable placeholder="請選擇" clearable="">
              <el-option
                v-for="item in organizations"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="標籤">
            <el-select v-model="queryForm.tags" clearable multiple filterable placeholder="請選擇">
              <el-option
                v-for="tag in queryTags"
                :key="tag.id"
                :label="tag.name"
                :value="tag.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="currentPage=1;query(pageSize,0,sort, order, queryForm)">查詢</el-button>
          <el-button type="primary" icon="el-icon-plus" @click="addDevice" v-if="$store.state.auth.role === 'admin' | $store.state.auth.role === 'org-admin'">新增</el-button>
          <el-button type="primary" @click="batchEdit" v-if="$store.state.auth.role === 'admin' | $store.state.auth.role === 'org-admin'">批次編輯</el-button>
          <el-button type="danger" @click="batchDelete" v-if="$store.state.auth.role === 'admin' | $store.state.auth.role === 'org-admin'">批次刪除</el-button>
        </el-form-item>
      </el-form>

    </CRow>
    <CRow>
    <el-table
             @sort-change = "sortChange"
            :data="moonlightDevices"
            style="width:100%"
            border
            stripe
            empty-text="暫無數據"
            :default-sort = "{prop: 'dateCreated', order: 'descending'}"
            @selection-change="handleSelectionChange"
          >

           <el-table-column
              type="index"
              :index="indexFormatter"
              fixed
              >
            </el-table-column>
          <el-table-column
          fixed
            type="selection"
            width="45">
          </el-table-column>
            <el-table-column
                label="月光機序號"
                prop="serial_number"
                sortable = "custom"
            >
            </el-table-column>
               <el-table-column
                label="所屬單位"
                prop="organization.name"
            >
            </el-table-column>
            <el-table-column
                label="名稱"
                prop="name"
                sortable = "custom"
            >
            </el-table-column>

              <el-table-column
                label="標籤"
            >
             <template slot-scope="scope">
             
                   <span class="tag_group">
                     <el-tag v-for="tag in scope.row.tags" :key="tag.id" :type="tag.type">{{tag.name}}</el-tag>
                 </span>
                 
             </template>
            </el-table-column>
            <el-table-column
                label="生效日期"
                prop="start_date"
                sortable = "custom"
                :formatter="dateFormatter"
                width="150px"
            >
            </el-table-column>
            <el-table-column
                label="失效日期"
                prop="end_date"
                sortable = "custom"
                :formatter="dateFormatter"
                width="150px"
            >
            </el-table-column>
            <el-table-column
                label="備註"
                prop="memo"
                sortable = "custom"
            >
            </el-table-column>
                     <el-table-column
              fixed="right"
              label="操作"
              width="150"
              v-if="$store.state.auth.role === 'admin' | $store.state.auth.role === 'org-admin'">
              <template slot-scope="scope">
                <el-button
                  @click.native.prevent="editDevice(scope.row.id)"
                  
                  size="mini">
                  編輯
                </el-button>
                <el-button
                  @click.native.prevent="deleteDevice(scope.row.id)"
                  type="danger"
                  size="mini">
                  刪除
                </el-button>
              </template>
          </el-table-column>
          </el-table>

    </CRow>
    <CRow>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[10, 25, 50]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
    </CRow>
    <el-dialog title="批次修改" :visible.sync="dialogBatchEditFormVisible" v-loading="loading" element-loading-text="作業中">
      <el-form :model="batchForm" ref="batchForm">
        <el-form-item label="標籤動作" :label-width="formLabelWidth" prop="tagAction" >
          <el-radio-group v-model="batchForm.tagAction">
            <el-radio label="append">新增</el-radio>
            <el-radio label="remove">移除</el-radio>
            <el-radio label="replace">取代</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="標籤" class="tags" :label-width="formLabelWidth" prop="tags">
        <div style="all:initial;">
              <el-transfer
                v-model="batchForm.tags"
                filterable
                :titles="['候選', '已加入']"
                :props="{
                  key: 'id',
                  label: 'name'
                }"
                :data="tags">
                <span slot-scope="{ option }"><el-tag :type="option.type">{{ option.name }}</el-tag></span>
              </el-transfer>
          </div>
        </el-form-item>
        <el-form-item label="生效日期" :label-width="formLabelWidth" prop="start_date">
          <el-date-picker type="date" value-format="timestamp" placeholder="選擇日期"  v-model="batchForm.start_date"></el-date-picker>
        </el-form-item>
        <el-form-item label="失效日期" :label-width="formLabelWidth" prop="end_date">
          <el-date-picker type="date" value-format="timestamp" placeholder="選擇日期"  v-model="batchForm.end_date"></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogBatchEditFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitBatch">確 定</el-button>
      </div>
     </el-dialog>

    <el-dialog title="新增月光機" :visible.sync="dialogFormVisible">
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="月光機序號" :label-width="formLabelWidth" prop="serial_number">
          <el-input v-model="form.serial_number" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="所屬單位" :label-width="formLabelWidth" prop="organization">
            <el-select v-model="form.organization" filterable placeholder="請選擇" @change="onOrgChange">
              <el-option
                v-for="item in organizations"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="名稱" :label-width="formLabelWidth" prop="name">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="標籤" class="tags" :label-width="formLabelWidth" prop="tags">
          <div style="all:initial;">
              <el-transfer
                v-model="form.tags"
                filterable
                :titles="['候選', '已加入']"
                :props="{
                  key: 'id',
                  label: 'name'
                }"
                :data="tags">
                <span slot-scope="{ option }"><el-tag :type="option.type">{{ option.name }}</el-tag></span>
              </el-transfer>
          </div>
        </el-form-item>
        <el-form-item label="生效日期" :label-width="formLabelWidth" prop="start_date">
          <el-date-picker type="date" value-format="timestamp" placeholder="選擇日期"  v-model="form.start_date"></el-date-picker>
        </el-form-item>
        <el-form-item label="失效日期" :label-width="formLabelWidth" prop="end_date">
          <el-date-picker type="date" value-format="timestamp" placeholder="選擇日期"  v-model="form.end_date"></el-date-picker>
        </el-form-item>
          <el-form-item label="備註" :label-width="formLabelWidth" prop="memo">
            <el-input type="textarea" v-model="form.memo"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">確 定</el-button>
      </div>
  </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Vue from 'vue'
import moment from "moment-timezone";
import API from '@/api/'
Vue.filter('converDate',function(value){
  if(value){
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment(value).tz(tz).format('YYYY-MM-DD');
  }else{
    return value;
  }

})
export default {
  name: 'MoonlightDeviceList',
  created () {
          this.$store.dispatch('organization/list', {max: 1000, offset: 0}, {
          root: true
      });
    this.$store.dispatch('tag/list', {max: 1000, offset: 0}, {
          root: true
      });
      this.$store.dispatch('moonlightDevice/list', {max: this.max, offset: this.offset}, {
          root: true
      });
  },
  data() {
            var validateSerialNumber = (rule, value, callback) => {
            if (this.form.id) {
                 const data = this.getDeviceById(this.form.id);
                 if ( data.serial_number === value) {
                   callback();
                   return;
                 }
            } 
            API.moonlightDevice.search({serial_number: value}).then(res =>{
              if (res.data.length == 0) {
                  callback();
              } else {
                callback(new Error('已存在'));
              } 
            })
        };
        return {
          loading: false,
          multipleSelection: [],
          batchForm: {
            tagAction: '',
            tags: [],
            start_date: null,
            end_date: null
          },
          dialogBatchEditFormVisible: false,
          pageSize:10,
          currentPage:0,
          queryForm: {
            qstr: '',
            tags: [],
            organization: []
          },
          form: {
            id: null,
            serial_number:'',
            organization:null,
            name:'',
            tags:[],
            start_date:null,
            end_date:null,
            memo:''
          },
          tags: [],
          dialogFormVisible: false,
          formLabelWidth: '120px',
          rules: {
            serial_number: [
              { required: true, message: '請輸入月光機序號', trigger: 'blur' },
              { validator: validateSerialNumber, trigger: 'blur' },
            ],
            organization: [
              { required: true, message: '請選擇所屬單位', trigger: 'blur' },
            ],
            name: [
              { required: true, message: '請輸入名稱', trigger: 'blur' },
            ],
            start_date: [
              { required: true, message: '請輸生效日期', trigger: 'blur' },
            ],
          },
        }
  },
  methods:{
    batchDelete() {
      this.$confirm('此操作將永久刪除該月光機, 是否繼續?', '提示', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.dispatch('moonlightDevice/batchDelete', {devices: this.multipleSelection.map(i=>i.id)} , {
            root: true
          }).then( res => {
            this.query(this.pageSize,this.offset, this.sort, this.order,this.queryParams)
            this.$message({
            type: 'success',
            message: '删除成功!'
          });
          })
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },
    batchEdit() {
      this.$store.dispatch('tag/list', {max: 1000, offset: 0}, {
          root: true
      }).then(()=>{
        this.tags = this.queryTags;
      })
      if(this.$refs['batchForm']) {
        this.$refs['batchForm'].resetFields();
      }
      this.dialogBatchEditFormVisible = true;
    },
    handleSelectionChange(val) {
        this.multipleSelection = val;
    },
    submitBatch() {
      this.loading = true;
      this.$store.dispatch('moonlightDevice/batch', {devices: this.multipleSelection.map(i=>i.id),...this.batchForm}, {
            root: true
        }).then(()=>{
          this.loading = false;
          this.dialogBatchEditFormVisible =false;
          this.query(this.pageSize,this.offset, this.sort, this.order ,this.queryParams)
        })
    },
    addDevice() {
      this.$store.dispatch('organization/list', {max: 1000, offset: 0}, {
          root: true
      });
      if (this.$refs['form']) {
        this.$refs['form'].resetFields();
      }
      this.form.id = null;
      this.form.tags = [];
      this.form.serial_number='';
      this.form.organization='';
      this.form.name='';
      this.form.tags=[];
      this.form.start_date=null;
      this.form.end_date=null;
      this.form.memo='';
      
      this.dialogFormVisible = true;
      this.tags=[];
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query(val,this.offset, this.sort, this.order, this.queryForm);
      },
      handleCurrentChange(val) {
        this.query(this.pageSize,this.pageSize*(val-1), this.sort, this.order, this.queryForm);

      },
    query(max,offset, sort, order, param = {}) {
      this.$store.dispatch('moonlightDevice/list', {max: max, offset: offset, sort: sort, order: order, ...param}, {
          root: true
      });
    },
    indexFormatter(index){
     return `#${parseInt(this.offset)+ index + 1}`;

    },
    dateFormatter(row, column, cellValue){
      if(cellValue){
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return moment(parseInt(cellValue)).tz(tz).format('YYYY-MM-DD');
        //return moment(cellValue).format('YYYY-MM-DD');
      }else{
        return cellValue;
      }

    },
    onOrgChange(selectedValue) {
      this.tags = this.getOrganizationById(selectedValue).tags;
      this.form.tags = [];
    },
  submit() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
              if(this.form.id) {
                this.submitUpdate();
              } else {
                this.submitAdd();
              }
          } else {
            return false;
          }
        });
    },
    submitAdd() {
        this.$store.dispatch('moonlightDevice/add', this.form, {
          root: true
        }).then( res => {
        
        this.dialogFormVisible = false;
        this.form.id=null;
        if (this.$refs['form']) {
        this.$refs['form'].resetFields();
      }
        this.query(this.pageSize,this.offset, this.sort, this.order, this.queryForm)
      })
    },
    submitUpdate() {
      this.$store.dispatch('moonlightDevice/update', this.form , {
          root: true
      }).then( res => {
        this.dialogFormVisible = false;
        this.form.id=null;
        if (this.$refs['form']) {
        this.$refs['form'].resetFields();
      }
        this.query(this.pageSize,this.offset, this.sort, this.order, this.queryForm)
      })
    },
    editDevice(device_id) {
      if (this.$refs['form']) {
        this.$refs['form'].resetFields();
      }
      this.$store.dispatch('organization/list', {max: 1000, offset: 0} , {
          root: true
      }).then( res => {
        const data = this.getDeviceById(device_id);
        if(data.organization) {
          
          this.tags = this.getOrganizationById(data.organization.id).tags;
          this.form.organization = data.organization.id;
        } else {
          this.tags = [];
          this.form.organization = null;
        }
        
        this.form.serial_number = data.serial_number;
        
        this.form.id=data.id;
        this.form.name=data.name;
        this.form.memo=data.memo;
        if(data.tags){
          this.form.tags=data.tags.map(tag=>tag.id);
        } else {
          this.form.tags = [];
        }
        this.form.start_date= data.start_date;
        this.form.end_date= data.end_date;
       this.dialogFormVisible = true;
      })
       
    },
    deleteDevice(organization_id) {
      this.$confirm('此操作將永久刪除該月光機, 是否繼續?', '提示', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.dispatch('moonlightDevice/remove', organization_id , {
            root: true
          }).then( res => {
            this.query(this.pageSize,this.offset, this.sort, this.order, this.queryForm)
            this.$message({
            type: 'success',
            message: '删除成功!'
          });
          })
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });

    },
    sortChange(args) {
      if (args.order === null) {
        this.$store.commit('moonlightDevice/SET_SORT','id',{root: true})
        this.$store.commit('moonlightDevice/SET_ORDER','ascending',{root: true})
      } else {
        this.$store.commit('moonlightDevice/SET_SORT',args.prop,{root: true})
        this.$store.commit('moonlightDevice/SET_ORDER',args.order,{root: true})
      }
      
      this.query(this.pageSize,this.offset,this.sort,this.order, this.queryForm)
    },
  },
  computed: {
      ...mapGetters({
          // 取得資料夾列表
          moonlightDevices: 'moonlightDevice/moonlightDevices',
          total:'moonlightDevice/total',
          max:'moonlightDevice/max',
          offset:'moonlightDevice/offset',
          organizations: 'organization/organizations',
          getOrganizationById: 'organization/getOrganizationById',
          getDeviceById: 'moonlightDevice/getDeviceById',
          sort: 'moonlightDevice/sort',
          order: 'moonlightDevice/order',
          queryTags: 'tag/tags',
      })
  },
}
</script>

<style scoped>
  .tag_group .el-tag+.el-tag {
    margin-left: 10px;
  }
  .el-transfer >>> .el-transfer__buttons {
    width: 100px;
  }
  .el-transfer >>> .el-transfer__buttons .el-button+.el-button {
    margin-left: 0px;
  }
</style>
